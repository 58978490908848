import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import FancyHeader from '../components/fancy-header'
import FancyRule from '../components/FancyRule'
import Footer from '../components/footer'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import Disqus from '../components/shared/Disqus'
import WideRecipePreview from '../components/WideRecipePreview'
import YouTubeVideo from '../components/YouTubeVideo'
import { getMonthYear } from '../ui'
import styles from './week.module.css'

export default class WeekTemplate extends React.Component {
  render() {
    const week = get(this.props, 'data.contentfulIssue')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const disqusConfig = {
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: { identifier: `issue-${week.number}`, title: week.title },
    }

    const youTubeRecipe = week.recipes.find(recipe => recipe.youTubeId)
    const youTubeId = youTubeRecipe ? youTubeRecipe.youTubeId : null

    return (
      <Layout location={this.props.location}>
        <div className={styles.pageLayout}>
          <Helmet title={`Journey East Zine | Issue #${week.number}`}>
            <meta
              property="og:title"
              content={`Journey East Zine | Issue #${week.number}`}
            />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={week.title} />
            <meta property="og:image" content={week.illustration.fluid.src} />
            <meta
              property="og:url"
              content={`https://journeyeast.co/zine/${week.number}`}
            />
          </Helmet>
          <div className={styles.header}>
            <h1 className={styles.weekTitle}>
              Issue #{week.number}: {week.title}
            </h1>
            <h2 className={styles.dates}>{getMonthYear(week)}</h2>
            <p className={styles.tagline}>{week.tagline}</p>
          </div>
          <section className={styles.illo}>
            <Img alt={week.title} fluid={week.illustration.fluid} />
          </section>

          <section className={styles.pageContent}>
            <RichText field={week.content} />
            <FancyHeader title="The Recipes" />
            <section className={styles.recipes}>
              {week.recipes.map(node => (
                <WideRecipePreview key={node.slug} recipe={node} />
              ))}
            </section>
            {youTubeId && (
              <section className={styles.youTube}>
                <YouTubeVideo videoId={youTubeId} />
              </section>
            )}
            <RichText field={week.recipeDescription} />
            <section className={styles.weekSection}>
              <FancyHeader title="Plan of Attack" />
              <RichText field={week.planOfAttack} />
            </section>
            <section className={styles.weekSection}>
              <FancyHeader title="Food for Thought" />
              <RichText field={week.foodForThought} />
            </section>
            <FancyRule />
            <RichText field={week.outro} />
          </section>
        </div>
        <Disqus identifier={`week-${week.number}`} title={week.title} />
        <Footer />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query WeekByNumber($number: Int!) {
    contentfulIssue(number: { eq: $number }) {
      title
      number
      tagline
      issueDate
      recipes {
        title
        hanzi
        slug
        tagline {
          tagline
        }
        photo {
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        youTubeId
      }
      content {
        json
      }
      planOfAttack {
        json
      }
      recipeDescription {
        json
      }
      outro {
        json
      }
      foodForThought {
        json
        content {
          content {
            data {
              uri
            }
          }
        }
        foodForThought
      }
      illustration {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`
